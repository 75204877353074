import './App.css';
import React, { Suspense, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authenticationService } from './services/authentication.service';
import { useScreenSizeClass } from './utils/media-query';
import './utils/base64-icons.scss';
import { useIdleTimer } from 'react-idle-timer'
import { HelpContentProvider } from './components/HelpContentProvider';
import { initGA, logPageView } from './components/Analytics/Analytics';
import { measurementID } from './BaseUrl';

const Content = React.lazy(() => import('./components/Navigation/Content'));
const UnauthenticatedContent = React.lazy(() => import('./components/Navigation/UnauthenticatedContent'));

const App = () => {

  initGA();
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();

  const onIdle = () => {
    authenticationService.signOut();
  }

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 14400000
  });

  useEffect(() => {

    if (authenticationService.currentUserValue?.authToken) {
      if (window.location.href.indexOf("OpenReport") > -1 || window.location.href.indexOf("CustomerView") > -1 || window.location.href.indexOf("InvalidPage") > -1 || window.location.href.indexOf("SubmitRenewedPolicies") > -1 || window.location.href.indexOf("Unsubscribe") > -1 || window.location.href.indexOf("/PortfolioItem/") > -1 || window.location.href.indexOf("/Portfolio/") > -1  || window.location.href.indexOf("/Sign/Completed") > -1 || window.location.href.indexOf("/SelectionOrderSummary/") > -1 || window.location.href.indexOf("/SelectionOrderPrinDocument/") > -1 || window.location.href.indexOf("/Sign/SessionExpired") > -1) {
        return;
      }
      if (window.location.href.indexOf("ResetPassword") > -1 || window.location.href.indexOf("ForgotPassword") > -1 || window.location.href.indexOf("ResetLink") > -1 || window.location.href.indexOf("PasswordSet") > -1 || window.location.href.indexOf("Signup") > -1 || window.location.href.indexOf("ErrorPage") > -1) {
        authenticationService.signOut();
      }
      else {
        authenticationService.currentUser.subscribe(x => setCurrentUser(x));
      }
    }
  });

  useEffect(() => {
    if (authenticationService.currentUserValue?.authToken === undefined) {
      if (window.location.toString().includes("ResetPassword") || window.location.toString().includes("ForgotPassword") || window.location.toString().includes("ResetLink") || window.location.toString().includes("PasswordSet") || window.location.toString().includes("Signup") || window.location.toString().includes("ErrorPage") || window.location.toString().includes("InvalidPage") || window.location.toString().includes("CustomerView") || window.location.toString().includes("OpenReport") || window.location.toString().includes("SubmitRenewedPolicies") || window.location.toString().includes("Unsubscribe") || window.location.toString().includes("/PortfolioItem/") || window.location.toString().includes("/Portfolio/") || window.location.toString().includes("/Sign/Completed") || window.location.toString().includes("/SelectionOrderSummary/") || window.location.toString().includes("/SelectionOrderPrinDocument/") || window.location.toString().includes("/Sign/SessionExpired") ) {
        return;
      }
      else {
        navigate("/");
        authenticationService.signOut();
      }
    }
  }, [authenticationService.currentUserValue?.authToken]);


  useEffect(() => {
    const handleInvalidToken = e => {
      if (e.key === 'currentUser' && e.oldValue && !e.newValue) {
        authenticationService.signOut();
      }
    }

    window.addEventListener('storage', handleInvalidToken)

    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken)
    }
  }, [authenticationService.currentUserValue?.authToken])

  const screenSizeClass = useScreenSizeClass();

  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  if (currentUser) {
    return (
      <React.Fragment>
        <Suspense fallback={<div> </div>}>
          <div className={`app ${screenSizeClass}`}>
            <HelpContentProvider>
              <Content />
            </HelpContentProvider>
          </div>
        </Suspense>
      </React.Fragment>
    );
  }
  else {
    return (
      <React.Fragment>
        <Suspense fallback={<div> </div>}>
          <div className={`app ${screenSizeClass}`}>
            <UnauthenticatedContent />
          </div>
        </Suspense>
      </React.Fragment>
    );
  }
}

export default App


